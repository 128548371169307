import { PermissionAction } from '../permissions/permissions.model';
import { Feature } from './account.model';
import { ConfinedSpacePermit } from './confined-space.model';
import { GasMonitorReading, InstrumentStatus, SensorGasCode, SensorStatus } from './gas.model';
import { AnyItem, ItemType } from './item.model';
import { Point } from './location.model';

export enum EventType {
    MessageDelivered = 'message-delivered',
    PanicReceived = 'panic-received',
    GeofenceEnter = 'geofence-enter',
    GeofenceExit = 'geofence-exit',
    GeofenceDisconnect = 'geofence-disconnect',
    FacilityEnter = 'facility-enter',
    FacilityExit = 'facility-exit',
    LevelEnter = 'level-enter',
    LevelExit = 'level-exit',
    GasCodeReading = 'gas-code-reading',
    GasInstrumentReading = 'gas-instrument-reading',
    GasMonitorPair = 'gas-monitor-pair',
    GasMonitorUnpair = 'gas-monitor-unpair',
    EntryEnter = 'entry-enter',
    EntryExit = 'entry-exit',
    EntryAttendantIn = 'entry-attendant-in',
    EntryAttendantOut = 'entry-attendant-out',
    EntryWilmaIn = 'entry-wilma-in',
    EntryWilmaOut = 'entry-wilma-out',
    EntryLeft = 'entry-left',
    EntryEntrant = 'entry-entrant',
    AirReadingSubmission = 'air-reading-submission',
    FormSubmission = 'form-submission',
    FormAnswerSubmission = 'form-answer-submission',
    ConfinedSpacePermit = 'confined-space-permit',
    EntryImage = 'entry-image',
    WilmaAssigned = 'wilma-assigned',
    WilmaUnassigned = 'wilma-unassigned',
    WilmaNetworkChange = 'wilma-network-change',
    WilmaCharging = 'wilma-charging',
    WilmaBattery = 'wilma-battery',
    BeaconEnter = 'beacon-enter',
    BeaconExit = 'beacon-exit',
    BeaconDisconnect = 'beacon-disconnect',
    PeerEnter = 'peer-enter',
    PeerExit = 'peer-exit',
    MobileLogin = 'mobile-login',
    MobileLogout = 'mobile-logout',
    SetStatus = 'set-status',
    None = 'none',
    MobileLocationDenied = 'mobile-location-denied',
    Stationary = 'stationary',
    WaltAssigned = 'walt-assigned',
    WaltUnAssigned = 'walt-unassigned',
    WaltDocked = 'walt-docked',
    WaltUnDocked = 'walt-undocked',
    WaltLocation = 'walt-location',
    Moving = 'moving'
}

export enum WorkflowActionType {
    Alert = 'alert',
    Post = 'post',
    Form = 'form',
    Report = 'report',
    Sms = 'sms',
    Email = 'email',
    Notify = 'notify',
    Status = 'status',
    Tag = 'tag',
    FacilityAlert = 'facility-alert'
}

export interface AbstractBadgeEvent {
    id?: string;
    folderId?: string;
    facilityId?: string;
    date: string;
    personId?: string;
    personName?: string;
    registrationId?: string;
    type?: EventType;
    personCompany?: string;
    personCrafts?: string[];
    personTags?: string[];
}

export interface BadgeEvent extends AbstractBadgeEvent {
    location?: BadgeLocation;
    accuracy?: number;
    battery?: number;
    connectivity?: ConnectivityStatus;
    panic?: boolean;
    message?: string;
    gasMonitor?: GasMonitorReading;

    itemName?: string; // Not part of backend model
    formName?: string; // Not part of backend model
}

export interface GasMonitorEvent extends AbstractBadgeEvent {
    // type?: EventType.GasCodeReading | EventType.GasInstrumentReading;
    instrumentStatus?: InstrumentStatus;
    sensorCode?: SensorGasCode;
    sensorStatus?: SensorStatus;
}

export interface EntryEvent extends AbstractBadgeEvent {
    // type?: EventType.EntryEnter | EventType.EntryExit | EventType.EntryAttendantIn | EventType.EntryAttendantOut | EventType.EntryEntrant;
    itemId: string;
    entryId: string;
    confinedSpacePermit: ConfinedSpacePermit;
    initiatorId: string;
    reason?: string;
    wilmaId?: string;
}

export interface ConfinedSpacePermitEvent extends AbstractBadgeEvent {
    // type?: EventType.ConfinedSpacePermit;
    confinedSpacePermitCloseOpen: {
        closed?: Date;
        created?: Date;
        itemId: string;
        permitNumber: string;
    };
}

export interface AccountFeaturesUpdated {
    features: Feature[];
}

export interface EventUpdateResponse {
    date: Date;
    personId?: string;
    itemId?: string;
    registrationId?: string;
    gasMonitor?: GasMonitorReading;
    panic?: boolean;
    location?: Point;
    facilities?: any[];
}

export interface UpdateEvent {
    date: Date;
    personId?: string;
    type?: EventType;
}

export interface GeofenceEvent extends AbstractBadgeEvent {
    // type?: EventType.GeofenceEnter | EventType.GeofenceExit;
    disconnect?: boolean;
    geofenceId?: string;
    geofenceName?: string;
    geofenceTags?: string[];
    geofenceEntered?: string;
    visiting?: string;
}

export interface LevelEvent extends AbstractBadgeEvent {
    // type: EventType.LevelEnter | EventType.LevelExit;
    level: number;
}

export interface BeaconEvent extends AbstractBadgeEvent {
    // type?: EventType.BeaconEnter | EventType.BeaconExit;
    beaconId?: string;
    beaconName?: string;
    beaconLevel?: number;
    beaconEntered?: Date;
    disconnect?: boolean;
}

export interface FacilityEvent extends AbstractBadgeEvent {
    facilityId?: string;
    facilityName?: string;
    facilityTags?: string[];
}

export interface FormSubmissionEvent extends AbstractBadgeEvent {
    // type?: EventType.FormSubmission;
    formSubmissionId: string;
    formId: { id: string; version: string };
    formTags: string[];
    itemId?: string;
    permitNumber?: string;
    entryId?: string;
}

export interface AirReadingSubmissionEvent extends AbstractBadgeEvent {
    // type?: EventType.AirReadingSubmission;
    airReadingSubmissionId: string;
    itemId: string;
    permitNumber: string;
    entryId: string;
}

export interface ImageEvent extends AbstractBadgeEvent {
    // type: EventType.EntryImage;
    image?: string;
    video?: any;
    thumbnail?: string;
    imageIndex?: string;
    itemId?: string;
    entryId?: string;
    wilmaName?: string;
}

export interface LogoutEvent extends AbstractBadgeEvent {
    // type?: EventType.MobileLogout;
    geoFenceId?: string;
    geoFenceName?: string;
    geoFenceTags?: string[];
}

export interface PanicEvent extends AbstractBadgeEvent {
    // type?: EventType.PanicReceived;
    message?: string;
}

export interface WilmaEvent extends AbstractBadgeEvent {
    // type?: EventType.WilmaAssigned | EventType.WilmaUnassigned;
    itemId: string;
    entryId: string;
    wilmaId: string;
}

// Intersecting all the events like this causes the type property to become a type of 'never'
// unless you define it only in the AbstractBadgeEvent
export type AnyBadgeEvent = BadgeEvent & AirReadingSubmissionEvent & FormSubmissionEvent & EntryEvent & ConfinedSpacePermitEvent &
    GeofenceEvent & LevelEvent & PanicEvent & GasMonitorEvent & WilmaEvent & LogoutEvent & BeaconEvent;

export interface BadgeUpdate {
    id?: string;
    registrationIds?: string[];
    connected?: Date;
    connectedCount?: number;
    disconnected?: Date;
    heartbeat?: Date;
    token?: string;
    name?: string;
    type?: BadgeType;
    version?: string;
    video?: boolean;
    date?: string;
    location?: Point;
    altitude?: number;
    level?: number;
    offline?: Date;
    exitedAt?: string; // ISO date
    accuracy?: number;
    battery?: number;
    connectivity?: ConnectivityStatus;
    panic?: boolean;
    message?: string;
    gasMonitor?: GasMonitorReading;
    geofences?: Array<{
        id: string;
        facilityId: string;
        name?: string;
        date?: Date;
    }>;
    facilities?: Array<{
        id: string;
        date?: Date;
        peers?: Array<{
            id?: string;
            distance?: number;
            date?: Date;
        }>;
        beacons?: Array<{
            id?: string;
            name?: string;
            level?: number;
            distance?: number;
            date?: Date;
        }>;
    }>;
    beacons?: Array<{
        id?: string;
        distance?: number;
        rssi?: number;
    }>;
    peers?: Array<{
        id?: string;
        distance?: number;
        rssi?: number;
    }>;
    locationDate?: Date;
    locationType?: string;
    deviceType?: 'walt' | 'android' | 'ios';
    deviceId?: string;
    grantedLocationPermissions?: {
        fine: boolean;
        coarse: boolean;
        background: boolean;
    };
    locationDetails?: {
        method?: string,
        contributors?: [{
            type?: 'beacon' | 'wifi' | 'cell' | 'peer',
            id?: string,
            name?: string
        }],
        accuracy?: number
    };
}

export enum ConnectivityStatus {
    None = 'none',
    Wifi = 'wifi',
    Cell2g = '2g',
    Cell3g = '3g',
    Cell4g = '4g'
}

export enum BadgeType {
    Badge = 'badge',
    Mobile = 'mobile'
}

export interface ItemUpdate {
    items?: { [key: string]: AnyItem };
    itemTypes?: { [key: string]: ItemType };
}

/** [longitude, latitude] */
export type BadgeLocation = [number, number];

export const eventTypeToPermissions: { [key in EventType]?: PermissionAction } = {
    [EventType.FormSubmission]: PermissionAction.ViewFormSubmissions
};

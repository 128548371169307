import { GasSetting } from './gas.model';
import { Point, Vertices } from './location.model';

export const BEACON_NUMBER_PREFIX = 'weavix-';
export const BEACON_NUMBER_PREFIX_DISPLAY = 'BEACON ';
export const DEFAULT_BEACON_ENTER_DISTANCE = 5;
export const DEFAULT_BEACON_ENTER_TIME = 15000;
export const DEFAULT_BEACON_EXIT_DISTANCE = 5;
export const DEFAULT_BEACON_EXIT_TIME = 15000;

export interface Beacon {
    id: string;
    facilityId: string;
    structureId?: string;
    location: Point;
    name: string;
    level: number;
    tracking?: boolean;
    event?: boolean;
    battery?: number;
    enterDistance?: number[];
    enterTime?: number;
    exitDistance?: number[];
    exitTime?: number;
    lastSeen?: string;
}

export interface TrackingCircle {
    latitude: number;
    longitude: number;
    radius: number;
}

export enum WifiRouterUsage {
    Static = 'static',
    Dynamic = 'dynamic',
    Ignored = 'ignored'
}

export interface WifiRouter {
    id: string;
    ssid: string;
    location: Point;
    lastSeen: string;
    usage: WifiRouterUsage;
    name?: string;
}

export interface UpdateWifiRouter {
    id: string;
    usage?: string;
    location?: number[];
    name?: string;
}

export interface AddWifiRouter {
    id: string;
    facilityId: string;
    ssid: string;
    location: Point;
    usage: WifiRouterUsage;
    lastSeen: string;
}

export interface Wrangler {
    id: string;
    facilityId: string;
    name: string;
    location: Point;
    structureId?: string;
    level?: number;
}

export interface UpdateWrangler {
    id: {
        facilityId: string;
        wranglerId: string;
    };
    name: string;
    location: Point;
    structureId?: string;
    level?: number;
}

export interface FacilityPerson {
    id: string;
    group: string;
}

export interface AddFacilityPersonRequest {
    personId: string;
    facilityId: string;
    group: string;
}

export interface Facility {
    id?: string;
    accountId: string;
    name: string;
    address: string;
    location: Point;
    bounds: Vertices;
    zoom: number;
    image?: {
        uri?: string;
        placement?: {
            bounds: Vertices;
            rotation: number;
            vertices: Vertices;
        };
    };
    tags?: string[];
    folderId?: string;
    timezone?: string;
    people?: FacilityPerson[];
    settings?: FacilitySettings;
    beacons?: Beacon[];
    wranglers?: Wrangler[];
    trackingCircles?: TrackingCircle[];
}

export interface FacilitySettings {
    gases: GasSetting[];
    readingsInterval: number;
    peer?: {
        enterDistance?: number[];
        enterTime?: number;
        exitDistance?: number[];
        exitTime?: number;
    };
    beacon?: {
        enterDistance?: number[];
        enterTime?: number;
        exitDistance?: number[];
        nearestBuffer?: number;
        exitTime?: number;
    };
    leaving?: {
        enabled?: boolean;
        title: string;
        body: string;
    };
    polling?: {
        stationary?: number;
        location: number;
        locationTimeoutHrs?: number;
    };
    walt?: {
        minWifiRssi?: number;
        minWifiLteDifference?: number;
    };
    excludeCompanies?: string[];
}

export type UpdateSettingsRequest = Partial<FacilitySettings>;

export interface Simulator {
    personCount?: number;
    itemCount?: number;
    running?: boolean;
    error?: string;
    enabled?: Date;
    disabled?: Date;
}


export const addBeaconNumberPrefix = (id: string): string => {
    const beaconId = id?.replace(new RegExp(BEACON_NUMBER_PREFIX, 'i'), '');
    return `${BEACON_NUMBER_PREFIX}${beaconId}`;
};

export const removeBeaconNumberPrefix = (beacon: Beacon): Beacon => {
    const beaconId = beacon.id?.replace(new RegExp(BEACON_NUMBER_PREFIX, 'i'), '');
    return Object.assign({}, beacon, { id: beaconId });
};

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Account } from '../models/account.model';
import { ChangePinRequest } from '../models/person.model';
import { Topic } from '@weavix/models/src/topic/topic';
import { ImageUpload } from '../models/upload.model';
import { PushPayload, UpdateUser, UserBadge, UserProfile, UserQrLogin } from '../models/user.model';
import { PermissionChecker } from '../permissions/permission-checker';
import { PermissionAction, PermissionApiResponse } from '../permissions/permissions.model';
import { AlertService } from './alert.service';
import { FacilityService } from './facility.service';
import { ACCOUNT_HEADER, HttpService } from './http.service';
import { PubSubService } from './pub-sub.service';

const THIRTY_MINUTES = 1800000;

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    constructor(
        private httpService: HttpService,
        private alertsService: AlertService,
        private pubSubService: PubSubService,
        private facilityService: FacilityService,
    ) {}

    userProfile: UserProfile;
    private permissions: PermissionApiResponse;
    private permissionChecker: PermissionChecker;
    private accounts: Account[];
    private nextUserBadgeUpdateTicks: number;
    profileUpdateSubject: Subject<UserProfile> = new Subject();

    updateUserProfileBadges(badge: UserBadge) {
        this.userProfile.badges = badge;
    }

    async refreshMyBadge(component: any): Promise<UserBadge> {
        if (!this.userProfile) return;
        if (
            this.nextUserBadgeUpdateTicks !== undefined
            && this.nextUserBadgeUpdateTicks > Date.now()
            && this.userProfile.badges
        ) return this.userProfile.badges;

        this.nextUserBadgeUpdateTicks = Date.now() + THIRTY_MINUTES;
        this.userProfile.badges = await this.httpService.get<UserBadge>(component, '/core/me/badge-refresh');
        return this.userProfile.badges;
    }

    async getUserProfile(component, force: boolean = false): Promise<UserProfile> {
        if (this.userProfile && !force) return this.userProfile;
        try {
            const profile = await this.httpService.get(component, '/core/me/profile');
            this.accounts = await this.httpService.get(component, '/core/me/accounts');
            this.permissions = await this.httpService.get(component, '/core/me/account/permissions-v2');
            this.permissionChecker = new PermissionChecker(this.permissions);
            // eslint-disable-next-line no-console
            console.log('this.permissions :>> ', this.permissions);

            this.userProfile = profile;
            await this.pubSubService.loggedIn();
            return this.userProfile;
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PROFILE.GET');
            this.alertsService.setAppLoading(false);
            console.error(e);

            return {
                id: null,
                avatarFile: null,
                firstName: null,
                lastName: null,
            };
        }
    }

    async subscribeNotifications(component) {
        const profile = await this.getUserProfile(component);
        return await this.pubSubService.subscribe<PushPayload>(component, Topic.UserNotification, [profile.id]);
    }

    async subscribeBadgeUpdates(component) {
        const profile = await this.getUserProfile(component);
        return await this.pubSubService.subscribe<UserBadge>(component, Topic.UserBadgeUpdate, [profile.id]);
    }

    hasPermission(action: PermissionAction, facilityId?: string, folderId?: string) {
        facilityId = facilityId ?? this.facilityService.getCurrentFacilityId();
        if (!this.permissions) return true;
        return this.permissionChecker.hasPermission(action, facilityId, folderId);
    }

    hasPermissionInAnyFacility(action: PermissionAction) {
        if (!this.permissions) return true;
        return this.permissionChecker.hasPermissionInAnyFacility(action);
    }

    hasAnyPermission(action: PermissionAction, facilityId?: string, folderId?: string) {
        facilityId = facilityId ?? this.facilityService.getCurrentFacilityId();
        if (!this.permissions) return true;
        return this.permissionChecker.hasAnyPermission(action, facilityId, folderId);
    }

    hasAnyPermissionInAnyFacility(action: PermissionAction) {
        if (!this.permissions) return true;
        return this.permissionChecker.hasAnyPermission(action);
    }

    hasAccountPermission(action: PermissionAction) {
        return this.permissionChecker.hasAccountPermission(action);
    }

    addFolder(id: string, parentId: string) {
        if (!this.permissions) return;
        this.permissionChecker.addFolder(id, parentId);
    }

    isMaster() {
        return this.accounts?.some(x => x.master);
    }

    async updateUserProfile(component, id: string, update: UpdateUser) {
        try {
            const updatedProfile = await this.httpService.put<UserProfile>(component, `/core/users/${id}`, update);
            this.profileUpdateSubject.next(updatedProfile);
            this.userProfile = updatedProfile;
            return updatedProfile;
        } catch (e) {
            this.alertsService.sendError(e, 'ERRORS.PROFILE.UPDATE');
            this.alertsService.setAppLoading(false);

            throw (e);
        }
    }

    async uploadAvatar(component, fd: FormData): Promise<ImageUpload> {
        try {
            fd.append('mirrored', 'true');
            return await this.httpService.upload<ImageUpload>(component, '/core/uploads/avatar', fd);
        } catch (e) {
            if (e.error?.details?.reason === 'file_too_large') {
                this.alertsService.sendError(e, 'ERRORS.FILE.TOO-LARGE');
            } else if (e.error?.details?.reason === 'no_face') {
                this.alertsService.sendError(e, 'ERRORS.FILE.NO-FACE');
            } else {
                this.alertsService.sendError(e, 'ERRORS.UPLOAD');
            }
            this.alertsService.setAppLoading(false);

            throw (e);
        }
    }

    async confirmFace(component, fd: FormData, accountId: string) {
        try {
            HttpService.headers[ACCOUNT_HEADER] = accountId;
            const face =  await this.httpService.upload<any>(component, '/core/uploads/confirm-face', fd);
            delete HttpService.headers[ACCOUNT_HEADER];
            return !!face;
        } catch (e) {
            console.error(e);
            return false;
        }
    }

    async getQrLogin(component, id: string): Promise<UserQrLogin> {
        try {
            return await this.httpService.get(component, `/core/users/${id}/qr-login`);
        } catch (e) {
            console.error(e);
        }
    }

    async generateNewQrLogin(component, id: string): Promise<UserQrLogin> {
        try {
            return await this.httpService.put(component, `/core/users/${id}/generate-new-qr-login`, {});
        } catch (e) {
            console.error(e);
        }
    }

    async resetQrPin(component, id: string, pin?: string): Promise<UserQrLogin> {
        try {
            return await this.httpService.put(component, `/core/users/${id}/reset-qr-pin`, { pin });
        } catch (e) {
            console.error(e);
        }
    }

    async adminResetPin(component, request: ChangePinRequest) {
        return this.httpService.post<any>(component, '/account/admin-change-pin', request);
    }
}

import { Subject } from 'rxjs';
import { Person } from '../models/person.model';
import { Walt } from '../models/walt.model';
import { MapWalt } from '../models/weavix-map.model';
import { Utils } from '../utils/utils';
import { PersonService } from './person.service';
import { StateServiceBase, StateUpdate } from './state-base.service';
import { WaltService } from './walt.service';

export class WaltStateService extends StateServiceBase {
    private people: Map<string, Person> = new Map();
    private personToWaltMap: Map<string, MapWalt> = new Map();
    walts: Map<string, MapWalt> = new Map();
    walts$: Subject<StateUpdate<Walt>> = new Subject();

    constructor(
        private waltService: WaltService,
        private personService: PersonService
    ) {
        super();
    }

    private addPersonToWalt(w: MapWalt): MapWalt {
        w = { ...w, person: this.people.get(w.lastAssigned.personId) };
        this.personToWaltMap.set(w.lastAssigned.personId, w);
        return w;
    }

    async loadData(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        const allPeople = await this.personService.getPeople(c);
        this.people = Utils.toMap(allPeople);
        const all = !facilityId ? await this.waltService.getAllByAccount(c, accountId)
            : await this.waltService.getAllByFacility(c, facilityId);
        const allWithPerson = [];

        all.forEach(w => {
            let walt: any = w;
            if (w.lastAssigned.personId) walt = this.addPersonToWalt(w);
            allWithPerson.push(walt);
        });
        this.walts = Utils.toMap(allWithPerson);
    }

    async stop() {
        super.stop();
        this.walts.clear();
    }

    protected async setupSubscriptions(c: any, accountId: string, facilityId?: string, tags?: string[]) {
        const subs = [
            (await this.waltService.subscribeWaltUpdates(c)).subscribe(async (result) => {
                let w: any = result.payload;
                if (w.lastAssigned.personId) w = this.addPersonToWalt(w);
                if (!w.loggedIn) {
                    const currWalt = this.walts.get(w.id);
                    if (currWalt) currWalt.loggedIn = null;
                }
                if (!w.poweredOff) w.poweredOff = null;
                if (!w.lastAssigned) w.lastAssigned = null;
                this.updateFromMap(this.walts, { [w.id]: w }, this.walts$);
            }),
            (await this.personService.subscribePersonUpdates(c)).subscribe(async (result) => {
                const person = result.payload;
                this.people.set(person.id, person);
                const personWalt = this.personToWaltMap.get(person.id);
                if (personWalt) {
                    personWalt.person = person;
                    this.updateFromMap(this.walts, { [personWalt.id]: personWalt }, this.walts$);
                }
            }),
        ];
        return subs;
    }
}
